<template>
    <div>
        <Dialog :visible.sync="showDialog"
                position="center"
                :modal="true"
                :closeOnEscape="true"
                :closable="true"
                style="width: 500px;"
                @update:visible="closeDialog"
        >
            <template #header>
                <h3 class="p-mb-0">Принятие. {{ title }}</h3>
            </template>
            <div class="p-d-flex p-flex-column p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0 errorPos"
                 style="max-width: 1200px;"
            >
                <DataTable
                    :value="worksheets"
                    dataKey="id"
                    class="p-datatable-striped role-list"
                    rowGroupMode="subheader"
                    groupRowsBy="group.id"
                    stripedRows
                    :scrollable="true"
                    responsiveLayout="scroll"
                    :paginator="false"
                    currentPageReportTemplate=""
                >
                    <Column field="group.id" />
                    <Column header="Лист" headerStyle="width: 20%" field="attributes.name" />
                    <Column header="Статус" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <span :style="`background: ${ data.status.bgColor }; color: ${ data.status.textColor }; padding: 4px; border-radius: 4px;`"
                            >{{ data.status.label }}</span>
                        </template>
                    </Column>
                    <Column header="Комментарий">
                        <template #body="{ data }">
                            <Textarea v-model="comments[data.id]"
                                :key="`worksheet-comment${ data.id }`"
                                placeholder="Напишите комментарий"
                                :autoResize="true"
                                rows="1"
                                cols="30"
                                @click="changeComment"
                            />
                        </template>
                    </Column>
                    <Column header="Принять" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <input type="hidden" :value="acceptedIds[data.id]" />
                            <div class="p-checkbox">
                                <div :ref="`accepted_${ data.id }`"
                                     :class="['p-checkbox-box', acceptedIds[data.id] ? 'p-highlight' : '' ]"
                                     @click="changeAcceptedStatus($event, data)"
                                     style="z-index: 1;"
                                >
                                    <span :class="['p-checkbox-icon', acceptedIds[data.id] ? 'pi pi-check' : '' ]"></span>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Отклонить" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <input type="hidden" :value="declinedIds[data.id]" />
                            <div class="p-checkbox declined">
                                <div :ref="`declined_${ data.id }`"
                                     :class="['p-checkbox-box', declinedIds[data.id] ? 'p-highlight' : '' ]"
                                     @click="changeDeclinedStatus($event, data)"
                                     style="z-index: 1;"
                                >
                                    <span :class="['p-checkbox-icon', declinedIds[data.id] ? 'pi pi-check' : '' ]"></span>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <template #groupheader="{ data }">
                        <div class="p-d-flex p-flex-row">
                            <span class="p-d-flex p-jc-between" style="width: 80%;">{{ data.group.name }}</span>
                            <span class="p-d-flex p-ai-center" style="width: 10%;">
                                <input type="hidden" :value="acceptedGroupIds[data.group.id]" />
                                <div class="p-checkbox" style="margin-left: 22px;">
                                    <div :ref="`accepted_${ data.group.id }`"
                                         :class="['p-checkbox-box', acceptedGroupIds[data.group.id] ? 'p-highlight' : '' ]"
                                         @click="changeGroupAcceptedStatus($event, data.group.id)"
                                         style="z-index: 1;"
                                    >
                                        <span :class="['p-checkbox-icon', acceptedGroupIds[data.group.id] ? 'pi pi-check' : '' ]"></span>
                                    </div>
                                </div>
                            </span>
                            <span class="p-d-flex p-ai-center" style="width: 10%;">
                                <input type="hidden" :value="declinedGroupIds[data.group.id]" />
                                <div class="p-checkbox declined" style="margin-left: 24px;">
                                    <div :ref="`declined_${ data.group.id }`"
                                         :class="['p-checkbox-box', declinedGroupIds[data.group.id] ? 'p-highlight' : '' ]"
                                         @click="changeGroupDeclinedStatus($event, data.group.id)"
                                    >
                                        <span :class="['p-checkbox-icon', declinedGroupIds[data.group.id] ? 'pi pi-check' : '' ]" style="z-index: 0;"></span>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </template>
                </DataTable>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start" style="margin-top: 16px;">
                    <Button class="p-button" type="button" @click="acceptForm">
                        <span class="p-button-label">Сохранить</span><span class="p-ink"></span>
                    </Button>
                    <Button class="p-button p-button-outlined" @click="closeDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
export default {
    name: 'acceptFormDialog',
    props: {
        showAcceptDialog: {
            require: true,
            type: Boolean
        },

        title: {
            require: true,
            type: String
        },

        worksheets: {
            require: true,
            type: Array
        },

        acceptedWorksheetIds: {
            type: Array
        },

        declinedWorksheetIds: {
            type: Array
        },

        acceptedGroups:  {
            type: Array
        },

        declinedGroups:  {
            type: Array
        },

        comments: {
            type: Array
        }
    },

    emits: [ 'loadingChange', 'acceptForm', 'closeAcceptFormDialog' ],

    data() {
        return {
            showDialog: this.showAcceptDialog,
            acceptedIds: this.acceptedWorksheetIds,
            declinedIds: this.declinedWorksheetIds,
            acceptedGroupIds: this.acceptedGroups,
            declinedGroupIds: this.declinedGroups,
        }
    },

    methods: {
        changeComment(event) {
            if (0 < event.target.scrollHeight) {
                event.target.style.height = `${ event.target.scrollHeight }px`;
            }
        },

        acceptForm() {
            this.showDialog = false;

            this.$emit('acceptForm', this.acceptedIds, this.declinedIds, this.comments);
        },

        closeDialog() {
            this.showDialog = false;
            this.$emit('closeAcceptFormDialog');
        },

        acceptGroupHandler(groupId) {
            const groupWorksheets = this.worksheets.filter(w => w.group?.id === groupId);

            if (this.acceptedGroupIds[groupId]) {
                /** Сняли выбор с отклонённой группы */
                if (this.declinedGroupIds[groupId]) {
                    this.declinedGroupIds[groupId] = false;
                    this.changeCheckbox(this.$refs[`declined_${ groupId }`]);
                }

                /** Проставили всем листам Отклонено */
                groupWorksheets.forEach((worksheet) => {
                    if (!this.acceptedIds[worksheet.id]) {
                        this.acceptedIds[worksheet.id] = true;
                        this.changeCheckbox(this.$refs[`accepted_${ worksheet.id }`]);
                    }
                    if (this.declinedIds[worksheet.id]) {
                        this.declinedIds[worksheet.id] = false;
                        this.changeCheckbox(this.$refs[`declined_${ worksheet.id }`]);
                    }
                });
            } else {
                /** Сняли с принятых листов галки */
                groupWorksheets.forEach((worksheet) => {
                    if (this.acceptedIds[worksheet.id]) {
                        this.acceptedIds[worksheet.id] = false;
                        this.changeCheckbox(this.$refs[`accepted_${ worksheet.id }`]);
                    }
                });
            }
        },

        declineGroupHandler(groupId) {
            const groupWorksheets = this.worksheets.filter(w => w.group?.id === groupId);

            if (this.declinedGroupIds[groupId]) {
                /** Сняли выбор с принятой группы */
                if (this.acceptedGroupIds[groupId]) {
                    this.acceptedGroupIds[groupId] = false;
                    this.changeCheckbox(this.$refs[`accepted_${ groupId }`]);
                }

                /** Проставили всем листам Принято */
                groupWorksheets.forEach((worksheet) => {
                    if (!this.declinedIds[worksheet.id]) {
                        this.declinedIds[worksheet.id] = true;
                        this.changeCheckbox(this.$refs[`declined_${ worksheet.id }`]);
                    }
                    if (this.acceptedIds[worksheet.id]) {
                        this.acceptedIds[worksheet.id] = false;
                        this.changeCheckbox(this.$refs[`accepted_${ worksheet.id }`]);
                    }
                });
            } else {
                /** Сняли с отклонённых листов галки */
                groupWorksheets.forEach((worksheet) => {
                    if (this.declinedIds[worksheet.id]) {
                        this.declinedIds[worksheet.id] = false;
                        this.changeCheckbox(this.$refs[`declined_${ worksheet.id }`]);
                    }
                });
            }
        },

        changeGroupAcceptedStatus(event, groupId) {
            let value = this.acceptedGroupIds[groupId];
            if (event.target.firstChild) {
                event.target.classList.toggle('p-highlight');
                event.target.firstChild.classList.toggle('pi');
                event.target.firstChild.classList.toggle('pi-check');
            } else {
                event.target.parentNode.classList.toggle('p-highlight');
                event.target.classList.toggle('pi');
                event.target.classList.toggle('pi-check');
            }
            this.acceptedGroupIds[groupId] = !value;
            this.acceptGroupHandler(groupId);
        },

        changeGroupDeclinedStatus(event, groupId) {
            let value = this.declinedGroupIds[groupId];
            if (event.target.firstChild) {
                event.target.classList.toggle('p-highlight');
                event.target.firstChild.classList.toggle('pi');
                event.target.firstChild.classList.toggle('pi-check');
            } else {
                event.target.parentNode.classList.toggle('p-highlight');
                event.target.classList.toggle('pi');
                event.target.classList.toggle('pi-check');
            }
            this.declinedGroupIds[groupId] = !value;
            this.declineGroupHandler(groupId);
        },

        changeCheckbox(element) {
            element.classList.toggle('p-highlight');
            element.lastChild.classList.toggle('pi');
            element.lastChild.classList.toggle('pi-check');
        },

        changeAcceptedStatus(event, worksheet) {
            let value = this.acceptedIds[worksheet.id];
            if (event.target.firstChild) {
                event.target.classList.toggle('p-highlight');
                event.target.firstChild.classList.toggle('pi');
                event.target.firstChild.classList.toggle('pi-check');
            } else {
                event.target.parentNode.classList.toggle('p-highlight');
                event.target.classList.toggle('pi');
                event.target.classList.toggle('pi-check');
            }
            this.acceptedIds[worksheet.id] = !value;
            this.worksheetHandler(worksheet, 'accept');
        },

        changeDeclinedStatus(event, worksheet) {
            let value = this.declinedIds[worksheet.id];
            if (event.target.firstChild) {
                event.target.classList.toggle('p-highlight');
                event.target.firstChild.classList.toggle('pi');
                event.target.firstChild.classList.toggle('pi-check');
            } else {
                event.target.parentNode.classList.toggle('p-highlight');
                event.target.classList.toggle('pi');
                event.target.classList.toggle('pi-check');
            }
            this.declinedIds[worksheet.id] = !value;
            this.worksheetHandler(worksheet, 'decline');
        },

        worksheetHandler(worksheet, type) {
            if (type === 'accept') {
                /** Снять выбор с отклонённого листа */
                if (this.acceptedIds[worksheet.id]) {
                    if (this.declinedIds[worksheet.id]) {
                        this.declinedIds[worksheet.id] = false;
                        this.changeCheckbox(this.$refs[`declined_${worksheet.id}`]);
                    }

                    if (worksheet.group && !this.acceptedGroupIds[worksheet.group.id]) {
                        const groupWorksheets = this.worksheets.filter(w => w.group?.id === worksheet.group.id);
                        let accepted = 0;

                        groupWorksheets.forEach((item) => {
                            if (this.acceptedIds[item.id]) {
                                accepted++;
                            }
                        })

                        if (accepted === groupWorksheets.length) {
                            if (!this.acceptedGroupIds[worksheet.group.id]) {
                                this.acceptedGroupIds[worksheet.group.id] = true;
                                this.changeCheckbox(this.$refs[`accepted_${worksheet.group.id}`]);
                            }
                            if (this.declinedGroupIds[worksheet.group.id]) {
                                this.declinedGroupIds[worksheet.group.id] = false;
                                this.changeCheckbox(this.$refs[`declined_${worksheet.group.id}`]);
                            }
                        }
                    }
                } else {
                    if (worksheet.group && this.acceptedGroupIds[worksheet.group.id]) {
                       this.acceptedGroupIds[worksheet.group.id] = false;
                       this.changeCheckbox(this.$refs[`accepted_${ worksheet.group.id }`]);
                    }
                }
            }

            if (type === 'decline') {
                if (this.declinedIds[worksheet.id]) {
                    /** Снять выбор с принятого листа */
                    if (this.acceptedIds[worksheet.id]) {
                        this.acceptedIds[worksheet.id] = false;
                        this.changeCheckbox(this.$refs[`accepted_${worksheet.id}`]);
                    }

                    if (worksheet.group && !this.declinedGroupIds[worksheet.group.id]) {
                        const groupWorksheets = this.worksheets.filter(w => w.group?.id === worksheet.group.id);
                        let declined = 0;

                        groupWorksheets.forEach((item) => {
                            if (this.declinedIds[item.id]) {
                                declined++;
                            }
                        })

                        if (declined === groupWorksheets.length) {
                            if (!this.declinedGroupIds[worksheet.group.id]) {
                                this.declinedGroupIds[worksheet.group.id] = true;
                                this.changeCheckbox(this.$refs[`declined_${worksheet.group.id}`]);
                            }
                            if (this.acceptedGroupIds[worksheet.group.id]) {
                                this.acceptedGroupIds[worksheet.group.id] = false;
                                this.changeCheckbox(this.$refs[`accepted_${worksheet.group.id}`]);
                            }
                        }
                    }
                } else {
                    if (worksheet.group && this.declinedGroupIds[worksheet.group.id]) {
                        this.declinedGroupIds[worksheet.group.id] = false;
                        this.changeCheckbox(this.$refs[`declined_${ worksheet.group.id }`]);
                    }
                }
            }
        }
    },

    watch: {
        showAcceptDialog: {
            handler(to) {
                if (to) {
                    this.showDialog = this.showAcceptDialog;
                }
            }
        },

        acceptedWorksheetIds: {
            handler(to) {
                if (to) {
                    this.acceptedIds = this.acceptedWorksheetIds;
                    this.declinedIds = this.declinedWorksheetIds;
                    this.acceptedGroupIds = this.acceptedGroups;
                    this.declinedGroupIds = this.declinedGroups;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .p-rowgroup-header {
    background-color: #ccc !important;
    align-items: center;
    color: #272727 !important;
}

::v-deep .p-datatable-scrollable-body {
    overflow: hidden;
}

.worksheet-name {
    display: block;
    text-align: center;
}

::v-deep .p-checkbox{
    &.declined .p-highlight {
        border-color: #ff6767;
        background: #ff6767;
    }
    &.declined .p-checkbox-box.p-highlight:hover {
        border-color: #ff6767;
        background: #ff6767;
    }
    &:not(.p-checkbox-disabled).declined .p-checkbox-box:hover {
        border-color: #ff6767;
    }
}
</style>
